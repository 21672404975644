<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        {{ message }}
        <v-text-field hide-details="" label="CIF" class="my-3" prepend-icon="mdi-card-bulleted" filled dense v-model="cif"></v-text-field>
      </v-card-text>
      <v-card-actions class="pt-0" >
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" :disabled="!cif" text @click.native="save">Guardar</v-btn>
        <!-- <v-btn color="info" text @click.native="saveMethod = !saveMethod">Manual</v-btn> -->
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  data: () => ({
    cif: null,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    validarCIF(cif) {
      if (!cif || cif.length !== 9) {
        return false;
      }

      var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
      var digits = cif.substr(1, cif.length - 2);
      var letter = cif.substr(0, 1);
      var control = cif.substr(cif.length - 1);
      var sum = 0;
      var i;
      var digit;

      if (!letter.match(/[A-Z]/)) {
        return false;
      }

      for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);

        if (isNaN(digit)) {
          return false;
        }

        if (i % 2 === 0) {
          digit *= 2;
          if (digit > 9) {
            digit = parseInt(digit / 10) + (digit % 10);
          }

          sum += digit;
        } else {
          sum += digit;
        }
      }

      sum %= 10;
      if (sum !== 0) {
        digit = 10 - sum;
      } else {
        digit = sum;
      }

      if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
      }
      if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
      }

      return String(digit) === control || letters[digit] === control;
    },
    open(title, message) {
      this.dialog = true
      this.title = title
      this.message = message
      // this.saveMethod = saveMethod
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    save() {
      if(this.validarCIF(this.cif)){
        this.resolve(this.cif)
        this.cif = null
        this.dialog = false
      }
      else{
        this.$root.$emit("snack", "Introduce un Cif válido.")
      }
    },
    cancel() {
      this.resolve(false)
      this.cif = null
      this.dialog = false
    }
  }
}
</script>